import { graphql } from 'gatsby';
import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Layout } from '../../components/layout/layout';
import { textOptions } from '../../util/rich-text';
import {
  DesktopAuthorCard,
  MobileAuthorCard,
} from '../../components/cards/author-card';
import { PageHeading } from '../../components/layout/page-heading';
import FootprintsLayout from '../../components/layout/footprints-layout';

const CreativePiece = ({ data }) => {
  const creativePiece = data.contentfulCreativePiece;
  return (
    <Layout title={creativePiece.title}>
      <FootprintsLayout>
        <div className="flex mt-6">
          <DesktopAuthorCard author={creativePiece.author} />
          <main className="md:px-4 md:ml-8 flex flex-col items-center text-center">
            <PageHeading title={creativePiece.title} />
            <MobileAuthorCard author={creativePiece.author} />
            <div className="mt-6 text-center md:text-left">
              {creativePiece.content &&
                renderRichText(creativePiece.content, textOptions)}
            </div>
          </main>
        </div>
      </FootprintsLayout>
    </Layout>
  );
};

export default CreativePiece;

export const query = graphql`
  query ($slug: String!) {
    contentfulCreativePiece(slug: { eq: $slug }) {
      title
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            fixed(width: 800) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
      author {
        name
        description {
          description
          childMarkdownRemark {
            html
          }
        }
        displayPicture {
          fixed(width: 400) {
            width
            height
            src
            srcSet
          }
        }
      }
    }
  }
`;
